import React, { useEffect, useState } from 'react'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import GeneralTextInput from '../../_atomic-design/molecules/Input/GeneralTextInput'
import Textinput from '../../_atomic-design/molecules/Input/TextInputGray'
// import GeneralHeading from '../../_atomic-design/atoms/Text/GeneralHeading'
import QuizRadioInput from '../../_atomic-design/molecules/Input/QuizRadioInput'
// import ButtonPrimaryXs from '../../_atomic-design/atoms/Button/ButtonPrimaryXs'
import inputMarksValidation from '../../helpers/InputMarksValidation'
import { toast } from 'react-toastify'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import ImageViewBox from '../../_atomic-design/molecules/Images/ImageViewBox'
// import ButtonSecondarySm from '../../_atomic-design/atoms/Button/ButtonSecondarySm'

export default function ResponseBlockObjective ({ item, questionID, questionResponse, updateMarksHandler, index, instructorResponse, setInstructorResponse, readOnly, mlEvaluation, instructorEvaluation }) {
  const [icomment, setIcomment] = useState((instructorResponse[index] && instructorResponse[index].instructorComments) ? instructorResponse[index].instructorComments : '')

  useEffect(() => {
    const newScoreArray = [...instructorResponse]
    newScoreArray[index] = { ...instructorResponse[index], instructorComments: icomment }
    setInstructorResponse(newScoreArray)
  }, [icomment])

  const regex = /(<([^>]+)>)/ig
  questionResponse = questionResponse || { markSecured: 0, submittedAnswers: '' }
  function marksChangeHandler (e) {
    e.preventDefault()
    const isValidMarks = inputMarksValidation(+e.target.value, 0, item.score)
    if (!isValidMarks) {
      toast('invalid marks')
      e.target.value = null
      return
    }
    const newScoreArray = [...instructorResponse]
    newScoreArray[index] = { questionID: questionID, markSecured: +e.target.value }
    setInstructorResponse(newScoreArray)
  }

  function commentsChangeHandler (e) {
    e.preventDefault()
    setIcomment(e.target.value)
  }

  const showQzkraftScore = !readOnly ? true : mlEvaluation && !instructorEvaluation
  /* this function needs to rewritten , it overwrites the newscore[index] with completely new value
  insted of using spread operator */
  function approve () {
    const newScoreArray = [...instructorResponse]
    newScoreArray[index] = { questionID: questionID, markSecured: questionResponse.markSecured, instructorComments: icomment }
    setInstructorResponse(newScoreArray)
  }
  const dummyValueAns = ((questionResponse.submittedAnswers !== '') && (questionResponse.submittedAnswers !== undefined) && (questionResponse.submittedAnswers !== null)) ? item.questChoices[questionResponse.submittedAnswers].replace(regex, '') : ''
  let instResponse = (instructorResponse[index] && ((instructorResponse[index].markSecured !== undefined) && (instructorResponse[index].markSecured !== null) && (instructorResponse[index].markSecured !== null))) ? instructorResponse[index].markSecured : ''
  instResponse = (readOnly && instResponse === '') ? 0 : instResponse
  // const insComment = (instructorResponse[index] && instructorResponse[index].instructorComments) ? instructorResponse[index].instructorComments : ''

  return (<div className='p-4 sm:p-6 bg-[#EDEDED] my-6 rounded-lg'>
    <div className='rounded-lg p-4  sm:p-10 sm:pb-1 relative bg-white mx-auto '>
        <GeneralText text={`Question no.: ${index + 1}`} className='font-bold bg-gradient-to-r  from-[#3C38CD] to-[#CE8CDD] p-1 px-4 rounded-md h-max w-max  absolute -left-8 text-white mr-2' />
        <GeneralText text={`Full Marks: ${item.score}`} className='font-bold bg-app-secondary p-1 px-4 h-max w-max absolute right-4 sm:right-10 top-4 rounded-md  text-white' />
        <div className='flex flex-col relative sm:left-24 top-8 sm:top-2 sm:w-4/5'>
          <GeneralText text={item.questionContent ? item.questionContent.replace(regex, '') : ''} />
          {item.questionPicture && <ImageTag src={item.questionPicture} className='my-4 max-w-[300px] max-h-[300px]' />}
          {item.questChoices.map((op, index) => {
            return <div key={op.replace(regex, '')} className='flex flex-col my-1'>
            <div className='flex'>
              <QuizRadioInput value={op.replace(regex, '')} name={op.replace(regex, '')} selected={dummyValueAns} onChange={ () => {}}/>
              <GeneralText text={op.replace(regex, '')} className='ml-2'/>
            </div>
            <ImageViewBox className={'max-w-[200px] max-h-[200px]'} image={(item.options_details && item.options_details[index] && item.options_details[index].img_url) || ''} /></div>
          })}
        </div>
        <div className='flex flex-col my-6 text-gray-700 '>
          <div className='flex flex-col sm:flex-row my-2'>
          <GeneralText text={'Student\'s answer : '} className='font-bold text-app-primary w-40 whitespace-nowrap' />
          <GeneralText className={'w-[90%]'} text={dummyValueAns} />
          </div>
          <hr />
          <div className='flex flex-col sm:flex-row my-2'>
            <GeneralText text={'Correct answer : '} className='font-bold text-green-500 w-40 whitespace-nowrap' />
            <GeneralText className={'w-[90%]'} text={item.generatedAnswer[0] ? item.generatedAnswer[0].replace(regex, '') : ''} />
          </div>
        </div>
    </div>
    <div className='flex flex-col my-auto relative p-2 sm:p-10 sm:py-4 rounded-lg justify-between  bg-[#E4E4F3] font-bold'>
      <Textinput className={' w-full text-[#6B6B6B] relative mx-auto my-4 sm:mx-0 sm:mb-0'} disabled={readOnly} label={'Comments :'} placeholder={'Instructor comments'} value={icomment} onChange={commentsChangeHandler} />
      <div className=' my-auto h-max flex flex-wrap mt-4 gap-2 text-white'>
            {showQzkraftScore && <div className='flex flex-row my-auto w-max h-max pl-4 relative whitespace-nowrap rounded-3xl text-center bg-white text-app-secondary'>
              <div className='my-auto flex flex-row py-2'>
                <div className='my-auto'>learnkraft.ai score : </div>
                <GeneralTextInput className=' my-auto w-[45px] sm:w-[60px] rounded-lg px-2 read-only:true ' value={questionResponse.markSecured.toFixed(2)} disabled />
              </div>
            </div>}
            <div className='flex flex-row my-auto w-max h-max pl-4 relative whitespace-nowrap rounded-3xl text-center bg-white text-app-secondary'>
              <div className='my-auto flex flex-row py-2'>
                {readOnly
                  ? <div className='my-auto'>Score :</div>
                  : <div className='my-auto'>Instructor Score :</div>}
                <div className='ml-1'><GeneralTextInput disabled={readOnly} className={(instResponse !== '') ? 'w-[60px] rounded-lg px-1 bg-app-secondary text-white ' : ' bg-app-red rounded-lg w-[60px]'} type='Number' min="0" step="0.1"
                max={item.score} value={instResponse} onChange={marksChangeHandler} /></div>
              </div>
              {/* {!readOnly && <button onClick={approve} className='flex flex-row ml-2 my-auto w-max h-max p-1 text-xs rounded-3xl bg-gradient-to-r  from-[#3C38CD] to-[#CE8CDD] text-white '><div className='my-auto mx-2 whitespace-nowrap'>Same as learnkraft.ai</div><img src={plusTopic} alt='+'></img></button>} */}
              {!readOnly && <button onClick={approve} className='flex flex-row ml-2 my-auto w-max h-max p-2 text-xs rounded-3xl bg-gradient-to-r  from-[#3C38CD] to-[#CE8CDD] text-white '><input type='checkbox' checked={instResponse === questionResponse.markSecured} readOnly/><div className='my-auto mx-2 whitespace-nowrap'>Same as learnkraft.ai</div></button>}
            </div>
          </div>
        </div>
    </div>
  )
}

// return (
//   <div className='border rounded-lg shadow-lg p-5 w-[90%] bg-white mx-auto my-10'>
//     <div className='flex flex-row justify-between'>
//       <div className='flex flex-row'>
//       <GeneralText text={'Q No.: '} className='font-bold' />
//       <GeneralText text={index + 1} className='ml-2' />
//       </div>
//       <div className='flex border rounded-md px-2 py-1 bg-green-300  '>Full Marks : {item.score}</div>
//     </div>
//     <GeneralHeading text={'Question'} className='font-bold' />
//     <GeneralText text={item.questionContent ? item.questionContent.replace(regex, '') : ''} />
//     {item.questChoices.map((op) => {
//       return <div key={op.replace(regex, '')} className='flex my-1'><QuizRadioInput value={op.replace(regex, '')} name={op.replace(regex, '')} selected={dummyValueAns} onChange={ () => {}}/><GeneralText text={op.replace(regex, '')} className='ml-2'/></div>
//     })}
//     <GeneralText text={'Student\'s answer '} className='font-bold text-red-500' />
//     <GeneralText className={'my-1 p-1 border rounded-lg shadow-md bg-red-100'} text={dummyValueAns} />
//     <GeneralText text={'Correct answer '} className='font-bold text-green-600' />
//     <GeneralText className={'my-1 p-1 border rounded-lg shadow-md bg-green-100'} text={item.generatedAnswer[0].replace(regex, '')} />
//     <div>
//       <div className='flex flex-row my-2'>
//         <div className=''>learnkraft.ai Score : <GeneralTextInput className='border rounded-lg p-1 w-[80px] ml-1' value={questionResponse.markSecured.toFixed(2)} disabled /></div>
//         {!readOnly && <ButtonPrimaryXs className={' mx-4 '} text={'Copy'} onClick={approve} />}
//         <div className='ml-2'>Instructor Score : <GeneralTextInput disabled={readOnly} className={(instResponse !== '') ? 'border rounded-lg p-1 w-[80px] ml-1' : ' bg-red-500 border rounded-lg p-1 w-[80px] ml-1'} type='Number' min="0"
//       max={item.score} value={instResponse} onChange={marksChangeHandler} /></div>
//       </div>
//       <div className='flex flex-row justify-between'>
//       <Textinput className='' disabled={readOnly} label={'comments :'} placeholder={'Instructor comments'} value={insComment} onChange={commentsChangeHandler} />
//         {/* <div className='my-auto '><ButtonSecondarySm text={'update'} onClick={updateMarksHandler} /></div> */}
//       </div>
//     </div>
//   </div>
// )
