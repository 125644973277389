
const questions = [
  {
    id: 1,
    questionText: 'What type of content should I upload? ',
    answerText: 'Content that is very subjective, expressive, highly specialised in a domain, structured,descriptive, and factual gives the best results with learnkraft.ai . Make sure to omit images, charts, or diagrams to optimise results. '
  },
  {
    id: 2,
    questionText: 'What are the types of questions I can expect to generate using learnkraft.ai? ',
    answerText: 'learnkraft.ai can create both multiple-choice questions (MCQs) and subjective questions. '
  },
  // {
  //   id: 3,
  //   questionText: 'How I can generate ONE type of questions only using learnkraft.ai ? ',
  //   answerText: 'Please put a ZERO (0) against the type of question (Subjective or Objective). The system will not generate any questions against that type. '
  // },
  {
    id: 4,
    questionText: 'What languages does learnkraft.ai support? ',
    answerText: 'Currently, learnkraft.ai only supports the English language. However, we are working on other languages as well.'
  },
  {
    id: 5,
    questionText: 'Does learnkraft.ai support image files? ',
    answerText: 'No. Currently, learnkraft.ai supports word or text file formats, e.g., Docx, Doc, PDF, TXT, etc.'
  },
  {
    id: 6,
    questionText: 'What happens if I upload an image file or convert an image file into a PDF and upload it?',
    answerText: 'learnkraft.ai will not recognise your text from the image and hence will not be able to create accurate questions. '
  },
  {
    id: 7,
    questionText: 'Is there a limit to the number and size of documents I can upload? ',
    answerText: 'Yes. You can upload two maximum files whose cumulative size is less than or equal to 5 MB as a free user. However, we have different offerings for paid users. Please refer to the pricing page for more information.(<a href="https://www.learnkraft.ai/pricing" className= "text-blue-600 underline" > https://www.learnkraft.ai/pricing </a>)'
  },
  {
    id: 8,
    questionText: 'Can I choose the number of questions to generate? ',
    answerText: 'Yes. If you are a registered user, you can insert the number of subjective and MCQ questions to generate. However, the number of questions depends on the quality of the content you have provided. For an unregistered user, the system will auto-generate five subjective questions, five MCQs, or any number of (&lt; 5), you want to put. '
  },
  {
    id: 9,
    questionText: 'How do I know if the amount of content uploaded is sufficient? ',
    answerText: 'Here’s your checklist: <br> &#9673; Are all topics of assessment covered by the submitted content? <br> &#9673; Is there any document that contains a high percentage (70% or more) of irrelevant content? (Recommend to remove) <br> &#9673; Is there any document that contains a high percentage (70% or more) of images? (Recommend to remove) '
  },
  {
    id: 10,
    questionText: 'What happens if the system shows an error? ',
    answerText: 'There may be instances when the system shows you an error. Please read the error message carefully and restart the procedure again. If you are facing any difficulties, please email us at<a href="mailto:contact@dkraftlearning.com" className= "text-blue-600 underline" > contact@dkraftlearning.com </a>. '
  },
  {
    id: 11,
    questionText: 'Can I edit or modify the generated questions & answers? ',
    answerText: 'Yes, you can edit, modify, delete, or add to the questions and answers.'
  },
  {
    id: 12,
    questionText: 'How do I download the generated questions and answers? ',
    answerText: 'Please go to the right-hand side "Download" option. Click on "Rubric". Your questions and answers will be downloaded automatically. To download questions only, please go to the right-hand side "Download" option. Click on "Question". Your question papers will be downloaded automatically. <br> Do note that only if you are a registered user are you able to download the generated questions and answers.'
  },
  {
    id: 13,
    questionText: 'Who is the rightful owner of the content?',
    answerText: 'The input content remains under the user&#39;s ownership. learnkraft.ai only processes the content to generate questions and answers. The option to delete the content from the system is provided to the user before question creation. The user may exercise this right to delete, which will lead to the erasure of content as well as questions and answers from the system entirely. learnkraft.ai does not store or keep any of the content in the system.'
  }
  // {
  //   questionText: '',
  //   answerText: ''
  // }
]

export default questions
