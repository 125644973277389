import React, { useState, useEffect } from 'react'

import google from '../../assets/images/Icons/google.png'
// import Facebook from '../../assets/images/Icons/Facebook.svg'
import Linkedin from '../../assets/images/Icons/Linkedin.svg'
import TextInputGray from '../../_atomic-design/molecules/Input/TextInputGray'
import HeadingSm from '../../_atomic-design/atoms/Text/HeadingSm'
import TextXs from '../../_atomic-design/atoms/Text/TextXs'
import CustomHrAuth from '../../_atomic-design/molecules/Others/CustomHrAuth'
import Icon from '../../_atomic-design/molecules/Others/Icon'
import { useDispatch } from 'react-redux'
import { UserInfo, loginSucess } from '../../store/actions/authActions/authActions'
import LogoIcon from '../../assets/images/Icons/learnkraft.svg'
import TextSm from '../../_atomic-design/atoms/Text/TextSm'
import {
  makeAuthenticatedServerRequest,
  makeServerRequest
} from '../../utils/helpers'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import crossSrc from '../../assets/images/Icons/cross.svg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import { setAuthTokens, setUserDetails } from '../../utils/cookieStorage'
import { GOOGLE_LOGIN, LINKEDIN_LOGIN } from '../../config/config'
import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import TooltipLeft from '../../_atomic-design/molecules/Others/TooltipLeft'
import questionMark from '../../assets/images/Icons/questionMark.svg'

export default function AuthComponent ({ className, closeModal }) {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState(searchParams.get('mode') || 'login')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [eyeOpen, setEyeOpen] = useState(false)
  const [errormsg, setErrormsg] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const dispatch = useDispatch()

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  // Helper function to validate credentials
  const validateCredentials = () => {
    if (email === '' || password === '') {
      setErrormsg('Please fill out all the fields.')
      return false
    } else if (!isValidEmail(email)) {
      setErrormsg('Please provide a valid email.')
      return false
    } else if (password.length < 8) {
      setErrormsg('The password must be at least 8 characters long.')
      return false
    } else if (password.length > 100) {
      setErrormsg('The password must be smaller than 100 characters.')
      return false
    }
    setErrormsg('')
    return true
  }
  // Function to handle the submit button
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!validateCredentials()) {
      return
    }
    if (mode === 'register') {
      createAccount()
    } else if (mode === 'login') {
      userLogin()
    }
  }

  // Calling to create account
  const createAccount = async () => {
    setIsDisabled(true)
    const res = await makeServerRequest('/auth/register', 'POST', {
      email,
      password
    })

    if (res && res.status === 200) {
      setAccessToken(res.data.accessToken)
      setRefreshToken(res.data.refreshToken)
      setErrormsg('')
    } else if ((res && res.status === 409)) {
      setErrormsg('Your email is already registered with us. Kindly login')
      setIsDisabled(false)
    } else if (res && res.status !== 200) {
      setErrormsg(res.data.error.message)
      setIsDisabled(false)
    }
  }

  // Calling to login
  const userLogin = async () => {
    setIsDisabled(true)
    const res = await makeServerRequest('/auth/login', 'POST', {
      email,
      password
    })
    if (res && res.status === 200) {
      setAccessToken(res.data.accessToken)
      setRefreshToken(res.data.refreshToken)
      setErrormsg('')
    } else if (res && res.status === 401) {
      setErrormsg('Please check your email and password, and try again.')
      setIsDisabled(false)
    } else if (res && res.status === 404) {
      setErrormsg('User not found please signup.')
      setIsDisabled(false)
    } else if (res && res.status !== 200) {
      setErrormsg('Please check your email and password, and try again.')
      setIsDisabled(false)
    }
  }

  // handle passwordEyeToggle
  const passwordEyeToggle = () => {
    setEyeOpen(!eyeOpen)
  }

  useEffect(async () => {
    // Setting the local storage tokens
    if (accessToken !== '' && refreshToken !== '') {
      setAuthTokens({ accessToken: accessToken, refreshToken: refreshToken })

      const userDetails = await makeAuthenticatedServerRequest('/profile', 'GET')

      if (userDetails && userDetails.status === 200) {
        // Store user details in cookies so no need to make API call everytime
        // setUserDetails(userDetails.data.user)
        dispatch(UserInfo({ ...userDetails.data.user }))
      }
      if (mode === 'login') {
        if (!userDetails.data.user.isEmailVerified) {
          navigate('/SendVerificationMail')
        } else {
          const usertype = userDetails.data.user.preferedRoleType ? userDetails.data.user.preferedRoleType : 0
          // confirm to go to student page otherwise cancel
          const userData = userDetails.data.user
          switch (usertype) {
            case 1:
              setUserDetails({ ...userData, isLoggedIn: true, userRoleType: '1' })
              dispatch(loginSucess({ ...userData, isLoggedIn: true, userRoleType: '1' }))
              if (!userData.detailsCompleted) {
                navigate('/AddMoreDetails')
              } else {
                navigate('/studentDashboard')
              }
              return
            case 0:
              setUserDetails({ ...userData, isLoggedIn: true, userRoleType: '0' })
              dispatch(loginSucess({ ...userData, isLoggedIn: true, userRoleType: '0' }))
              if (!userData.detailsCompleted) {
                navigate('/AddMoreDetails')
              } else {
                navigate('/Dashboard')
              }
              return
            default:
              navigate('/DashboardSelector')
          }
        }
      } else if (mode === 'register') {
        // Send the verification email
        await makeAuthenticatedServerRequest('/auth/sendVerificationToken', 'POST')
        navigate('/SendVerificationMail')
      }
    }
  }, [accessToken, refreshToken])

  useEffect(() => {
    setMode(searchParams.get('mode') || 'login')
    document.title = searchParams.get('mode') || 'login'
  }, [searchParams])

  useEffect(() => {
    setErrormsg('')
  }, [email, password, searchParams])

  return (
    <div className={'w-full max-w-[326px] sm:max-w-xl rounded-[11px] sm:rounded-[20px] bg-white py-7 sm:py-10 ' + className}>
        <div className="w-min mx-auto">
          {
            closeModal && <ImageTag src={crossSrc} onClick={closeModal} className='h-5 ml-auto relative -top-3 -right-2 sm:-right-16 cursor-pointer ' />
          }
          <div className="flex flex-row justify-center gap-4 my-5 ">
          <img className="w-2/3 text-center" src={LogoIcon} alt="LOGO" />
          </div>
          <HeadingSm
            className="text-center"
            text={mode === 'register' ? 'Please create your account first. ' : ''}
          />
          <TextSm
              className={'mt-2 text-center'}
              text={mode === 'register' ? 'Sign up with ' : 'Login with'}
            />
          <div className="flex flex-row justify-center gap-4 my-5 ">
              <Icon href={GOOGLE_LOGIN} src={google} rel="noreferrer" />
              {/* <Icon href={FACEBOOK_LOGIN} src={Facebook} rel="noreferrer" /> */}
              <Icon href={LINKEDIN_LOGIN} src={Linkedin} rel="noreferrer" />
          </div>
          <CustomHrAuth />
          <form onSubmit={handleSubmit}>
          {
                errormsg !== '' &&
            <TextXs
              className={'mt-2 text-red-700'}
              text={errormsg}
            />
            }
            <TextInputGray
              className={'mb-4'}
              defaultValue={''}
              placeholder={'@mail.com'}
              label={'Email Address'}
              id={'email'}
              onChange={handleEmail}
            />
            <div className="relative">
            <TextInputGray
              className="block mt-4 "
              type={(eyeOpen === false) ? 'password' : 'text'}
              label={'Password'}
              placeholder={(eyeOpen === false) ? '********' : 'password'}
              on
              onChange={handlePassword}
            />
            <div className='text-2xl absolute top-6 sm:top-10 right-5'>
                      {
                          (eyeOpen === false)
                            ? <AiFillEye onClick={passwordEyeToggle}/>
                            : <AiFillEyeInvisible onClick={passwordEyeToggle}/>

                      }
            </div>
            {
              mode === 'register' && <div className={'w-5 h-max absolute -right-8 sm:-right-10 top-6 sm:top-10'}>
              <TooltipLeft
              message={'Password must be 8 characters at least and a maximum of 100 characters.'}
              content={<ImageTag src={questionMark} className={'w-5 h-max'} />}
              />
            </div>}
          </div>
            {/* {
                mode === 'register' &&
            <TextXs
              className={'mt-2 text-red-500'}
              text={'Password must be 8 characters at least and a maximum of 100 characters.'}
            />
            } */}
            <ButtonPrimaryLg text={`${mode === 'login' ? 'Login' : 'Create Account'}`} onClick={handleSubmit} className='my-2 sm:my-5' disabled={isDisabled}/>
            <div className="flex flex-col justify-center items-center gap-y-2 ">
              {
                mode === 'login' &&
                <Link className="text-app-primary text-xs sm:text-base" to="/ForgotPassword">
                Forgot Password
              </Link>
              }
              <span className='text-xs sm:text-base'>
                {mode === 'login' ? 'Don’t have an account? ' : 'Already have an account? ' }
                {
                  mode === 'login'
                    ? <Link to='/Auth?mode=register' className='text-app-primary font-semibold' > Create Account</Link>
                    : <Link to='/Auth?mode=login' className='text-app-primary font-semibold' > Login</Link>
                }
              </span>
            </div>
          </form>
        </div>
      </div>
  )
}
